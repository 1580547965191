import './ArticlePreview.scss';
import {
  parse,
  t,
  dateStringToHumanDate,
  parseWithBR,
  isTouchDevice,
  trimHeadline,
} from '../../lib/helper';
import { searchQueryState } from '../states/searchQueryState';
import { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate } from 'react-router-dom';
import { md5 } from 'js-md5';

import { currentUserState } from '../states/currentUserState';
import { useAtom } from 'jotai';
import { marked } from 'marked';

export function ArticlePreview({ website, key, compactView } = {}) {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useAtom(searchQueryState);
  const [showFullHeadline, setShowFullHeadline] = useState(false);
  const [headline, setHeadline] = useState(null);

  const [currentUser] = useAtom(currentUserState);

  const [colorIndex, setColorIndex] = useState(
    website?.url ? parseInt(md5(website.url).slice(0, 1), 16) % 5 : 0
  );

  function withoutWWW(url) {
    return url?.replace(/^http[s]*\:\/\/(www\.)*/, '');
  }

  function justDomain(url) {
    return withoutWWW(url)?.replace(/\/.+?$/, '');
  }

  function visitArticle() {
    navigate('/websites/' + website.share_url);
  }

  function handleTagClick(tag, append = false) {
    let query = `tag:${tag}`;

    if (append && !searchQuery?.includes(`tag:${tag}`)) {
      query = `${searchQuery || ''} ${query}`.trim();
    } else {
      query = query === searchQuery ? '' : query;
    }
    let params = new URLSearchParams({ q: query });
    navigate(location.pathname + '?' + params.toString(), {
      replace: true,
    });
  }

  function handleExternalUrlClick(url, append = false) {
    url = url.replace(/^http[s]*\:\/\/(www\.)*/, '');
    url = new URL('https://' + url).hostname;
    let query = `site:${url}`;

    if (append && !searchQuery?.includes(`site:${tag}`)) {
      query = `${searchQuery || ''} ${query}`.trim();
    } else {
      query = query === searchQuery ? '' : query;
    }
    let params = new URLSearchParams({ q: query });
    navigate(location.pathname + '?' + params.toString(), {
      replace: true,
    });
  }

  useEffect(() => {
    if (!website) {
      return;
    }
    setHeadline(website.translated.display_title || website.title || '');
  }, [website]);

  return (
    <div
      className="article-preview"
      key={key ? key : 'article-preview-' + website.id}
    >
      <div className="published-on">
        <div className="published-date">
          {dateStringToHumanDate(
            website.published_on || website.scraped_at,
            currentUser.language || website.display_lang,
            website.scraped_at
          )}
        </div>
        {website && website.process_status !== 'finished' && (
          <div
            className="processing-status"
            data-process-status={website.process_status}
          >
            {t(`${website.process_status}`)}
          </div>
        )}
      </div>
      <div
        className={[
          'headline',
          compactView || showFullHeadline ? 'full-headline' : '',
        ].join(' ')}
        onClick={() => {
          return visitArticle();
        }}
      >
        {headline ? (
          <>
            <h2 className="text-masked" data-gradient={colorIndex}>
              {parse(marked.parseInline(headline))}
            </h2>
            <div
              className="h2 short-headline text-masked"
              data-gradient={colorIndex}
            >
              {parse(marked.parseInline(trimHeadline(headline)))}
            </div>
          </>
        ) : (
          <>
            <h2 className="text-masked" data-gradient={colorIndex}>
              {t('Title could not be detected')}
            </h2>
            <div
              className="h2 short-headline text-masked"
              data-gradient={colorIndex}
            >
              {t('Title could not be detected')}
            </div>
          </>
        )}
      </div>

      <div className="article-tags">
        <ul>
          {website.major_categories?.map((tag) => (
            <li
              className="main"
              key={'tag-' + tag + website.id}
              onClick={() => handleTagClick(tag)}
            >
              {tag}
            </li>
          ))}
          {website.non_major_categories?.slice(0, 12)?.map((tag) => (
            <li
              key={'tag-' + tag + website.id}
              onClick={() => handleTagClick(tag)}
            >
              {tag}
            </li>
          ))}
        </ul>
      </div>
      {true && (
        <div className="image">
          {website.image_url ? (
            <LazyLoadImage
              src={website.image_url}
              alt={website.translated.display_title || website.title}
            ></LazyLoadImage>
          ) : (
            <div className="placeholder"></div>
          )}
        </div>
      )}

      <div
        className="summary"
        onClick={(ev) => {
          return visitArticle();
        }}
      >
        {!compactView ? (
          <>
            {(website.summary || website.custom_summary) &&
              parse(
                marked.parse(
                  website.custom_summary ||
                    website.translated.summary ||
                    website.summary
                )
              )}
          </>
        ) : (
          <>
            {parse(
              marked.parse(
                website.custom_summary ||
                  website.translated.detailed_summary ||
                  website.detailed_summary ||
                  website.translated.summary ||
                  website.summary
              )
            )}
          </>
        )}
      </div>

      <div className="core-data">
        <a
          className="source"
          onClick={(ev) => {
            ev.preventDefault();
            handleExternalUrlClick(website.url || website.scrape_url);
          }}
          target="_blank"
        >
          <span className="without-www">
            {withoutWWW(website.url || website.scrape_url)}
          </span>
          <span className="just-domain">
            {justDomain(website.url || website.scrape_url)}
          </span>
        </a>
      </div>

      <div className="call2action" onClick={() => visitArticle()}>
        <a
          href={`/websites/${website.share_url}`}
          className="text-masked"
          data-gradient={colorIndex}
        >
          {t('Read more')} &nbsp;→
        </a>
      </div>
    </div>
  );
}

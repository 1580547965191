import './WebsiteArticle.scss';
import { dateStringToHumanDate, t } from '../../lib/helper';
import * as api from '../../lib/api';
import { useEffect, useState, useRef } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useInterval } from 'usehooks-ts';
import {
  parseWithBR,
  parse,
  extractArticleFromHTML,
  getImageBrightness,
  isTouchDevice,
} from '../../lib/helper';
import { differenceInMinutes } from 'date-fns';
import ContentEditable from 'react-contenteditable';
import { WebsiteActions } from './WebsiteActions';
import debounce from 'lodash.debounce';
import { NotFound } from './NotFound';
import { marked } from 'marked';

const maxProcessTimeInMinutesBeforeRegardedAsFailure = 3;
const checkArticleChangesInterval = 8000;

export function WebsiteArticle({ setActionMenu }) {
  const params = useParams();
  const [article, setArticle] = useState(null);
  const [softLoading, setSoftLoading] = useState(null);
  const location = useLocation();
  const [showProcessingError, setShowProcessingError] = useState(false);
  const [articleText, setArticleText] = useState(null);
  const [imageHasChanged, setImageHasChanged] = useState(null);
  const [articleImageUrl, setArticleImageUrl] = useState(null);
  const [articleImageAspectRatio, setImageAspectRatio] = useState(null);
  const [customArticleTitle, setCustomArticleTitle] = useState(null);
  const [checkProcessStatus, setCheckProcessStatus] = useState(false);
  const [articleNotFound, setArticleNotFound] = useState(null);
  const [showOriginalContent, setShowOriginalContent] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const imageRef = useRef(null);

  useEffect(() => {
    if (!article) {
      return;
    }
    setArticleImageUrl(
      article.image?.url ||
        article.display_image_url ||
        article.image_url ||
        false
    );
    setImageAspectRatio(article.image?.aspect_ratio || null);
    setActionMenu(
      <WebsiteActions
        article={article}
        articleText={articleText}
        loadAndSetArticle={loadAndSetArticle}
        setArticle={setArticle}
        setSoftLoading={setSoftLoading}
        saveArticleCustomText={saveArticleCustomText}
      ></WebsiteActions>
    );
  }, [article, articleText]);

  async function handleImageLoading(ev) {
    setSoftLoading(false);
  }

  function bestCustomTextToDisplayFromArticle(article) {
    if (article.custom_text?.trim()) {
      return article.custom_text
        ?.replace(/\n/g, '<br>')
        ?.replace(/\n+/g, '<br>');
    }

    if (article.plain_content) {
      return article.plain_content
        .replace(/\n{1}/g, '<br>')
        .replace(/\n{1,}/g, '<br><br>');
    }

    if (article.html) {
      let result = extractArticleFromHTML(article.html);
      setCustomArticleTitle(result.title);
      if (result.textContent) {
        return result.textContent;
      }
    }
    return null;
  }

  async function saveArticleCustomText(custom_text = null) {
    if (!custom_text) {
      custom_text = articleText;
    }
    setSoftLoading(true);
    let res = await api.patchWebsite(article.id, {
      custom_text,
      share_custom_text: !custom_text ? false : article.share_custom_text,
    });
    if (res.website.custom_text === null) {
      // fill with content from firefox reader
      setArticleText(bestCustomTextToDisplayFromArticle(res.website));
    }
    setArticle(res.website);
    setSoftLoading(false);
  }

  useEffect(() => {
    if (!article) {
      return;
    }
    if (articleText === null) {
      setArticleText(bestCustomTextToDisplayFromArticle(article));
      if (article.html) {
        setCustomArticleTitle(extractArticleFromHTML(article.html).title);
      }
    }
  }, [articleText, article]);

  async function loadAndSetArticle(id) {
    try {
      setArticle(await api.getWebsite(id));
      setArticleNotFound(false);
    } catch (e) {
      console.error(e);
      setArticleNotFound(true);
    }
  }

  async function checkProcessStatusOfArticleAndDisplayErrorMessageIfNeeded() {
    let website = await api.getWebsite(article.id);
    if (
      website?.process_status !== 'finished' &&
      differenceInMinutes(new Date(), new Date(article.updated_at)) >
        maxProcessTimeInMinutesBeforeRegardedAsFailure
    ) {
      setShowProcessingError(true);
      return;
    }
    if (website?.process_status === 'failed') {
      setShowProcessingError(true);
      return;
    }
    if (website?.process_status === 'finished') {
      function setNewArticle(website) {
        setShowProcessingError(false);
        setArticle(website);
        // set image url again to trigger image reloading
        if (imageRef.current) {
          let imageUrl = imageRef.current.src;
          imageRef.current.src = null;
          imageRef.current.src = imageUrl;
        }
      }

      setNewArticle(website);

      if (article.process_status !== 'finished') {
        setTimeout(() => {
          setNewArticle(website);
        }, 4000);
      }
    } else {
      setArticle(website);
    }
  }

  useEffect(() => {
    if (params.websiteID && !article) {
      loadAndSetArticle(params.websiteID);
    }
  }, [params, article]);

  useEffect(() => {
    if (!article || article.process_status === 'finished') {
      return;
    }
    if (article.process_status === 'failed') {
      setShowProcessingError(true);
      return;
    }
    if (location.search.includes('?wait=queue')) {
      setCheckProcessStatus(true);
    } else if (
      differenceInMinutes(new Date(), new Date(article.updated_at)) >
      maxProcessTimeInMinutesBeforeRegardedAsFailure
    ) {
      setShowProcessingError(true);
    }
  }, [location, article]);

  useInterval(
    () => {
      checkProcessStatusOfArticleAndDisplayErrorMessageIfNeeded();
    },
    article &&
      checkProcessStatus &&
      (article.process_status === 'processing' ||
        article.process_status === 'queued') &&
      differenceInMinutes(new Date(), new Date(article.updated_at)) <=
        maxProcessTimeInMinutesBeforeRegardedAsFailure
      ? checkArticleChangesInterval
      : null
  );

  async function handleToggleTitle() {
    if (!article.headline) {
      return;
    }
    let { website } = await api.patchWebsite(article.id, {
      show_headline_instead_of_title: !article.show_headline_instead_of_title,
    });
    setArticle(website);
  }

  async function updateImageUrlForArticle(url = null, save = false) {
    setImageHasChanged(true);
    let { website } = await api.setDisplayImageOnWebsite(article.id, url, save);
    if (save) {
      setArticle(website);
    } else {
      let art = { ...article, ...{ display_image_url: url, image_url: url } };
      if (art.image) {
        art.image.url = url;
        art.image.aspect_ratio = null;
      }
      setArticle(art);
    }
    setSoftLoading(false);
    if (save) {
      setImageHasChanged(false);
    }
  }

  async function handleArticleTextChange(ev) {
    setArticleText(ev.target.value);
    saveArticleCustomText(ev.target.value);
  }

  return articleNotFound ? (
    <NotFound></NotFound>
  ) : (
    <div
      className={[
        'article',
        softLoading ? 'loading' : '',
        article?.process_status !== 'finished' ? 'not-finished' : '',
      ]
        .filter((v) => !!v)
        .join(' ')}
    >
      {article?.url && showProcessingError && (
        <div className="process-error">
          <p>{t('There was a problem while processing the url')}</p>

          {article.process_failed_reason ? (
            <p>
              <strong>{article.process_failed_reason}</strong>
            </p>
          ) : (
            <p>
              {t(
                `Maybe not all parts of the website are accessible or have relevant content? If the website is behind a paywall which you can access, you should try again with the browser plugin.`
              )}
            </p>
          )}
          <p>
            <a
              hreg="#"
              onClick={async (ev) => {
                ev.preventDefault;
                setSoftLoading(true);
                let website = (await api.setProcessStatusToFinished(article.id))
                  .website;
                setArticle(website);
                setShowProcessingError(false);
                setSoftLoading(false);
              }}
            >
              {t(
                'Alternatively you can read the article anyway, by clicking here'
              )}
            </a>
          </p>
        </div>
      )}
      {article && (
        <>
          <div className="published-on">
            {article.published_on || article.scraped_at ? (
              dateStringToHumanDate(
                article.published_on || article.scraped_at,
                article.display_lang,
                article.scraped_at
              )
            ) : (
              <div className="block-placeholder published-on">
                <span
                  className="block-placeholder-line"
                  style={{ width: '4rem' }}
                ></span>
                <span
                  className="block-placeholder-line"
                  style={{ width: '2rem' }}
                ></span>
                <span
                  className="block-placeholder-line"
                  style={{ width: '2rem' }}
                ></span>
              </div>
            )}
          </div>
          <div className="headline">
            <h2 onDoubleClick={() => handleToggleTitle()}>
              {article.title ? (
                parse(
                  marked.parseInline(
                    article.translated.display_title || article.display_title
                  )
                )
              ) : (
                <div className="block-placeholder headline">
                  <span
                    className="block-placeholder-line"
                    style={{ width: '20%' }}
                  ></span>
                  <span
                    className="block-placeholder-line"
                    style={{ width: '40%' }}
                  ></span>
                  <span
                    className="block-placeholder-line"
                    style={{ width: '70%' }}
                  ></span>
                  <span
                    className="block-placeholder-line"
                    style={{ width: '10%' }}
                  ></span>
                </div>
              )}
            </h2>
          </div>

          <div className="image-and-summary">
            <div
              className="summary summary-teaser"
              onDoubleClick={async () => {
                let { website } = await api.patchWebsite(article.id, {
                  show_takeaway: !article.show_takeaway,
                });
                setArticle(website);
              }}
            >
              {article.show_takeaway && article.takeaway ? (
                <>
                  {parse(
                    marked.parse(
                      article.translated.takeaway || article.takeaway
                    )
                  )}
                </>
              ) : (
                <>
                  {article.custom_summary || article.summary ? (
                    parse(
                      marked.parse(
                        article.custom_summary ||
                          article.translated.summary ||
                          article.summary
                      )
                    )
                  ) : (
                    <div className="block-placeholder summary">
                      {[1, 2, 3].map((i) => (
                        <>
                          <span
                            className="block-placeholder-line"
                            style={{ width: `${i * 30}%` }}
                          ></span>
                          <span className="block-placeholder-line"></span>
                          <span
                            className="block-placeholder-line"
                            style={{ width: '90%' }}
                          ></span>
                          <span
                            className="block-placeholder-line"
                            style={{ width: '95%' }}
                          ></span>
                          <span
                            className="block-placeholder-line"
                            style={{ width: '100%' }}
                          ></span>
                        </>
                      ))}
                    </div>
                  )}
                </>
              )}

              {article.summary && article.content_is_shortened_for_summary && (
                <div className="summary-warning">
                  {t('Note')}:{' '}
                  {t(
                    'The original text was too long. So parts from the end of the text are not included in the summary and may corrupt the correct gist.'
                  )}
                </div>
              )}
            </div>

            {article && (
              <div className="url">
                <a
                  href={article.url || article.scrape_url}
                  target="_blank"
                  className="source-url"
                  data-prefix-text={t('Source: ')}
                >
                  {article.url || article.scrape_url}
                </a>
              </div>
            )}

            {article.detailed_summary && (
              <div className="detailed-summary">
                <div className="summary">
                  {parse(
                    marked.parse(
                      article.translated.detailed_summary ||
                        article.detailed_summary
                    )
                  )}
                </div>
              </div>
            )}


            <div className="key-moments">
              <h3>{t('Key moments')}</h3>
              {parse(
                marked.parse(
                  article.translated.key_moments || article.key_moments
                )
              )}
            </div>

            {article?.process_status === 'finished' && (
              <>
                {!showImage ? (
                  <p>
                    <button
                      className="subtle"
                      onClick={() => setShowImage(true)}
                    >
                      {t('Show image')}
                    </button>
                  </p>
                ) : (
                  <>
                    {articleImageUrl !== false ? (
                      <div
                        style={{
                          '--aspect-ratio': articleImageAspectRatio || 'unset',
                        }}
                        className={[
                          'image',
                          articleImageUrl ? 'has-image' : null,
                        ]
                          .filter((v) => !!v)
                          .join(' ')}
                      >
                        {articleImageUrl && (
                          <div className="image-edit-container">
                            <img
                              src={articleImageUrl}
                              ref={imageRef}
                              onLoad={(ev) => handleImageLoading(ev)}
                              onError={() => {
                                // on initial load after processing the display_image_url is not ready to deliver, so use in this case the previous image_url instead
                                if (
                                  article.image_url &&
                                  /^http/.test(article.image_url)
                                ) {
                                  imageRef.src = article.image_url;
                                }
                              }}
                            ></img>
                            <div className="add-image-url">
                              <button
                                className="button small"
                                onClick={() => {
                                  let url = prompt(
                                    'Paste the image url you want to add'
                                  );
                                  if (url && url.match(/^http[s]*\:\/\//)) {
                                    updateImageUrlForArticle(url, true);
                                  }
                                }}
                              >
                                {t('Add image by url')}
                              </button>
                            </div>
                            <div className="save-image">
                              {imageHasChanged && article.display_image_url && (
                                <button
                                  className="small"
                                  onClick={() => {
                                    if (article.display_image_url) {
                                      updateImageUrlForArticle(
                                        article.display_image_url,
                                        true
                                      );
                                    }
                                  }}
                                >
                                  {t('Save')}
                                </button>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      article?.process_status !== 'finished' && (
                        <div className="block-placeholder image"></div>
                      )
                    )}
                  </>
                )}
              </>
            )}
          </div>

          {showOriginalContent && articleText ? (
            <div className="more-options">
              <br></br>

              <div className="all-content">
                <h3>{customArticleTitle || ''}</h3>
                {articleText &&
                  (isTouchDevice() || article?.process_status !== 'finished' ? (
                    <div className="custom-text">{parse(articleText)}</div>
                  ) : (
                    <ContentEditable
                      key={`custom-text-${article.id}`}
                      className="custom-text"
                      html={articleText}
                      onChange={debounce(handleArticleTextChange, 2500)} // handle innerHTML change
                    />
                  ))}
                <p>
                  <br></br>
                  <button
                    className="subtle"
                    onClick={() => setShowOriginalContent(false)}
                  >
                    {t('Hide original content')}
                  </button>
                </p>
              </div>
            </div>
          ) : (
            <>
              {articleText && (
                <div>
                  <button
                    className="subtle"
                    onClick={() => setShowOriginalContent(true)}
                  >
                    {t('Show original content')}
                  </button>
                </div>
              )}
            </>
          )}
        </>
      )}
      {article && article.scrape_url !== article.url && (
        <div className="url">
          <a
            href={article.url}
            target="_blank"
            className="source-url"
            data-prefix-text={t('Original URL: ')}
          >
            {article.scrape_url}
          </a>
        </div>
      )}
      {article && article.process_status !== 'finished' && (
        <>
          {article.process_status === 'failed' ? (
            <div className="error">
              Some problem occured while analyzing the website.<br></br>
              <Link to={'/add?url=' + article.url}>
                Please try again by adding the website again
              </Link>
            </div>
          ) : (
            <div></div>
            // <div className="loading-spinner"></div>
          )}
        </>
      )}
    </div>
  );
}
